/* The important stuff */
.container.top {
    transform: translateY(-3px) scaleX(.88235);
}

.container.bottom {
    transform: translateY(3px) scaleX(.88235);
}

input:checked+label .container .line.bottom {
    transform: rotateZ(45deg);
}

input:checked+label .container .line.top {
    transform: rotateZ(-45deg);
}

input:checked+label .container.bottom {
    transform: none;
}

input:checked+label .container.top {
    transform: none;
}

input:checked+label .line.bottom {
    transform: none;
    transition-delay: 0.1s;
}

input:checked+label .line.top {
    transform: none;
    transition-delay: 0.1s;
}

.container {
    transition: transform 0.2s ease-in-out 0.1s;
}

input:checked+label .container {
    transition-delay: 0s;
}

.line {
    transition: transform 0.2s ease-in-out;
}

/* The boilerplate stuff */
button {
    all: unset;
    cursor: pointer;
    display: block;
}

button * {
    pointer-events: none;
}

.burger {
    height: 40px;
    width: 40px;
    display: block;
    position: relative;
}

.container {
    position: absolute;
    right: 15px;
    top: 25px;
}

.line {
    height: 2px;
    border-radius: 3px;
    background: white;
    width: 30px;
}